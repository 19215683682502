<template>
  <transition
    name="slide-left"
    appear
    mode="out-in">
    <div>
      <div
        v-if="isLoadingCourses || isLoadingCategories"
        class="mt-12">
        <v-progress-linear
          indeterminate
          color="primaryColor"></v-progress-linear>
      </div>
      <div
        v-else
        class="px-12 py-7">
        <v-btn
          text
          small
          plain
          class="pa-0 mb-10"
          @click="$router.push({ name: 'Explore' })">
          <v-icon
            color="primaryColor"
            class="mr-4"
            >mdi-arrow-left</v-icon
          >
        </v-btn>
        <v-row
          align="center"
          no-gutters
          class="mb-12">
          <div class="d-flex mr-6">
            <v-img
              :src="currentCategory?.coverUrl"
              cover
              class="col-4 rounded-xl"
              width="96"
              height="96" />
          </div>

          <h1 class="category-name mr-12">{{ currentCategory?.name }}</h1>
          <v-btn
            depressed
            color="primaryColor"
            rounded
            class="filter-btn px-3 ml-12"
            @click="openCourseFiltersDialog">
            <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
            <span class="text-uppercase">Filters</span>
          </v-btn>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="offWhite"
          color="white"
          slider-size="40">
          <v-tab
            v-for="el in types"
            :key="el"
            class="tab-item offBlack--text text-capitalize"
            :ripple="false">
            <span style="z-index: 5">{{ el }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="el in types"
            :key="el">
            <div class="courses-grid-wrap mt-6">
              <CourseRowCard
                v-for="course in filteredCourses"
                :key="course.id"
                :course="course" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <course-filters-dialog
        :isDialogShown="showCourseFiltersDialog"
        :mediaType="mediaType"
        :maxTime="maxTime"
        @closeDialog="closeCourseFiltersDialog"
        @setMediaType="setMediaType"
        @setMaxTime="setMaxTime"
        @filterCourses="filterTabsCourses"
        @clearAllFilters="resetTabsCourses" />
    </div>
  </transition>
</template>

<script>
  import CoursesByCategoryMixin from '@/views/courses/courses-by-category/CoursesByCategory.mixin.vue';
  import CourseRowCard from '@/components/courses/course-row-card/CourseRowCard.desktop.vue';
  export default {
    mixins: [CoursesByCategoryMixin],
    components: {
      CourseRowCard,
    },
  };
</script>

<style>
  .v-tabs-bar {
    padding: 4px;
    border-radius: 12px;
  }
  .v-tabs-slider {
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(255, 255, 255, 0.08);
  }
  .v-tab::before {
    background-color: transparent;
  }
  .tab-item {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    width: 33%;
  }
</style>
<style lang="scss" scoped>
  .category-name {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .filter-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    height: unset !important;
    .v-image {
      filter: brightness(0) invert(1);
    }
  }
  .v-tabs {
    max-width: 342px;
  }
  .courses-grid-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, 278px);
    gap: 16px;
  }
</style>
